<template>
  <v-card>
    <template v-if="!topicData">
      <v-card-title>
        {{ $t('ScanTopicToCheckin') }}
      </v-card-title>

      <v-card-text>
        <QRCode
          v-model="qrData"
          class="qrcode"
        />
      </v-card-text>

      <v-card-text>
        <v-btn
          outlined
          block
          class="mt-4 mb-8"
          @click="handleGoBack"
        >
          {{ $t('Back') }}
        </v-btn>
      </v-card-text>
    </template>

    <template v-else>
      <v-card-text v-if="topicData.image_1_url">
        <v-img
          class="w-full mb-4"
          :src="topicData.image_1_url"
          :alt="`image ${topicData.image_1}`"
        />
      </v-card-text>

      <v-card-title style="line-height: 1.45">
        {{ topicData.name }}
      </v-card-title>

      <v-card-text>
        <strong>{{ $t('Speaker') }}</strong>: {{ topicData.speaker }}
      </v-card-text>

      <v-card-text>
        <strong>{{ $t('Time') }}</strong>: {{
          enrichTopicTime(
            formatTimestampToDatetime(topicData.start_time, 'DD/MM/YYYY HH:mm'),
            formatTimestampToDatetime(topicData.end_time, 'DD/MM/YYYY HH:mm')
          )
        }}
      </v-card-text>

      <v-card-text>
        <v-btn
          color="primary"
          block
          :loading="loading"
          @click="handleCheckinTopic"
        >
          {{ $t('Checkin') }}
        </v-btn>

        <v-btn
          class="mt-4"
          outlined
          block
          @click="handleRescanQrCode"
        >
          {{ $t('Back') }}
        </v-btn>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import QRCode from '@/components/QRCode.vue'
import SkillShareService from '@/services/SkillShareService'
import store from '@/store'
import { enrichTopicTime } from '@/utils/skillShareHelper'

export default {
  components: {
    QRCode,
  },
  setup() {
    const topicId = ref(null)
    const qrData = ref(null)
    const topicData = ref(null)
    const { router } = useRouter()
    const loading = ref(false)

    const getTopicData = async () => {
      try {
        const resp = await SkillShareService.getTopicDetail(topicId.value)

        topicData.value = resp.data.data.topic
      } catch {}
    }

    watch(qrData, value => {
      if (value) {
        topicId.value = qrData.value
        getTopicData()
        qrData.value = null
      }
    })

    const handleGoBack = () => {
      qrData.value = null
      topicData.value = null
      router.back()
    }

    const handleRescanQrCode = () => {
      qrData.value = null
      topicData.value = null
    }

    const handleCheckinTopic = async () => {
      try {
        loading.value = true
        await SkillShareService.checkinTopic(topicId.value)

        store.commit('snackbar/show', {
          message: 'Checkin event successfully',
          type: 'success',
        })
        router.push({ name: 'skill-share-detail', params: { id: topicId.value } })
      } catch (e) {
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      qrData,
      topicData,
      handleGoBack,
      handleRescanQrCode,
      handleCheckinTopic,
      enrichTopicTime,
    }
  },
}
</script>
